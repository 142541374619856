import React from "react"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div className="container my-16">
      <h1>Wuuuuups. Couldn't find something.</h1>
      <p>
        Ok!{" "}
        <a href="/" className="text-accent">
          Take me home.
        </a>
      </p>
    </div>
  </>
)

export default NotFoundPage
